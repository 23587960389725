// store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    homeTabIndex: 1,
    articleTypeConfig: {type: 1, name: '技术'},
    userInfo: {},
    articleCount: [],
    showMenu: false,
    markdown: '',
    isAdminWanted: false
  },
  mutations: {
    changeHomeTabIndex(state, index) {
      state.homeTabIndex = index;
    },
    changeArticleType(state, config) {
      state.articleTypeConfig = config;
    },
    changeUserInfo(state, info) {
      state.userInfo = info;
    },
    changeArticleCount(state, count) {
      state.articleCount = count;
    },
    changeShowMenu(state, status) {
      state.showMenu = status;
    },
    changeMarkDownContent(state, markdown) {
      state.markdown = markdown;
    },
    changeIsAdminWanted(state, isAdminWanted) {
      state.isAdminWanted = isAdminWanted;
    }
  }
});

export default store;