import { ElMessage } from 'element-plus'

/**
 * 格式化时间戳
 * @param {string} timestamp 13位毫秒时间戳
 * @returns formated time
 */
export function pdate(timestamp) {
  let date = new Date(parseInt(timestamp));
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  return `${y}-${m<10?('0'+m):m}-${d<10?('0'+d):d}`
}

/**
 * 格式化时间戳，如果在30天以内，则返回时间+天
 * @param {string} timestamp 13位毫秒时间戳
 * @returns formated time
 */
export function pdatePro(timestamp) {
  const inputDate = new Date(parseInt(timestamp));
  const currentDate = new Date();
  const diff = currentDate - inputDate;
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (days >= 1) {
    if (days <= 30) {
      return `约${days}天前`;
    } else {
      let y = inputDate.getFullYear();
      let m = inputDate.getMonth() + 1;
      let d = inputDate.getDate();
      return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`;
    }
  } else if (hours >= 1) {
    return `约${hours}小时前`;
  } else if (minutes >= 1) {
    return `约${minutes}分钟前`;
  } else {
    return "刚刚";
  }
}

/**
 * 生成uuid
 * @returns an uuid
 */
export function getUUID() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c);
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
      };
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, callback);
    }
  }
  let timestamp = new Date().getTime();
  let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perforNow + random) % 16 | 0;
      perforNow = Math.floor(perforNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};

/**
 * 复制文本内容
 * @param {string} content 待复制的文本内容
 */
export function copyContent(content) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content).then(() => {
      ElMessage({
        type: 'success',
        message: '已复制'
      })
    });
  } else {
    const ta = document.createElement("textarea");
    ta.setAttribute("style", "position:absolute;clip:rect(0 0 0 0)");
    ta.value = content;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy', true);
  }
  return true;
}

/**
 * 文本转doc文件
 * @param {string} title 
 * @param {string} content 
 */
export function text2Doc(title, content) {
  const el = document.createElement("a");
  el.setAttribute('download', `${title}.doc`);
  const blob = new Blob([content])
  el.setAttribute('href', URL.createObjectURL(blob));
  el.style.display = 'none';
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
  ElMessage({
    type: 'success',
    message: '已导出'
  })
}


// 创建一个函数来计算并显示运行时间
export function updateElapsedTime(start) {
  // 设置网站开始运行的时间
  let startTime = new Date(start);

  const now = new Date(); // 获取当前时间
  const elapsedTime = now - startTime; // 计算已经过去的时间（毫秒）

  // 将毫秒转换为天、小时、分钟和秒
  let seconds = Math.floor(elapsedTime / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  
  seconds %= 60;
  minutes %= 60;
  hours %= 24;

  // 更新网页上显示的内容
  return `${days}d ${hours}h ${minutes}m`;
}

// 检查设备
export function detectDeviceType() {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // 根据屏幕宽度判断设备类型
  if (screenWidth <= 800) {
      return 1; // 手机
  } else if (screenWidth > 800 && screenWidth <= 1200) {
      return 2; // 平板
  } else {
      return 2; // 电脑
  }
}
